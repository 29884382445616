<template>
    <v-card>
        <v-card-title>
            <div v-if="this.id">Свойства компетенции</div>
            <div v-else>Добавление новой компетенции</div>
            <a class="close" @click="$emit('close')">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                          fill="#0033A0"/>
                </svg>
            </a>
        </v-card-title>
        <v-card-text>
            <v-text-field label="Наименование компетенции" filled rounded v-model="data"></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-btn
                    @click="$emit('close');data='';"
            >
                Отмена
            </v-btn>
            <v-btn
                    @click="$emit('close', data);data='';"
                    color="blue"
            >
                Сохранить изменения
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

    export default {
        name: 'AddSkillDialog',
        components: {},
        props: {
            id: {
                default: '',
                type: String,
            },
            skill: {
                default: '',
                type: String,
            },
        },
        data() {
            return {
                data: this.skill
            }
        },
    }
</script>
